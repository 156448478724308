import * as React from 'react';
import { Link } from 'gatsby';
import logo from '../images/logo.png';
import './404.scss';

// markup
const NotFoundPage = () => {
  return (
    <div className="error-page">
      <img className="error-page__logo" src={logo} alt="Delfi logotyp" />
      <div className="error-page__content">
        <div className="error-page__inner">
          <h1 className="error-page__heading">Hoppsan, sidan du söker kunde inte hittas!</h1>
          <p>Vänligen prova igen eller kontakta Delfi support.</p>
          <div className="error-page__buttons">
            <a className="error-page__link-btn" href="." onClick={(e) => {
                e.preventDefault(); 
                window.history.back();
              }}>‹ Tillbaka</a>
            <Link className="error-page__link-btn" to="/">Till Delfi.se</Link>
          </div>
        </div>
        <ul className="error-page__contact">
            <li>Behöver du hjälp?</li>
            <li><a href="https://kundservice.delfi.se/">Kundservice</a></li>
            <li><a href="mailto:info@delfi.se">info@delfi.se</a></li>
        </ul>
      </div>
    </div>
  )
}

export default NotFoundPage
